import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { VerifyEmailPage } from "./VerifyEmailPage";

export const verifyEmail = typeCheckAppModule({
    NAME,
    Container: VerifyEmailPage,
    route: Routes.verifyEmail,
    secure: false,
});
