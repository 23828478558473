import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Heading, PublicLayout } from "src/components";
import { useResendEmailConfirmationMutation } from "src/serverApi";

type SignUpSuccessProps = Readonly<{
    email: string;
}>;

export const SignUpSuccess: FC<SignUpSuccessProps> = ({ email }) => {
    const { t } = useTranslation();

    const [resendConfirmation, { isLoading: isResending }] = useResendEmailConfirmationMutation();

    return (
        <PublicLayout>
            <Heading level={2}>{t("signUp.success.card.header")}</Heading>
            <div className="space-y-6">
                <p>{t("signUp.success.message.email", { email })}</p>
                <p>{t("signUp.success.message.verification")}</p>
                <Button fullWidth onClick={() => resendConfirmation({ email })} isLoading={isResending}>
                    {t("signUp.success.resend.button")}
                </Button>
            </div>
        </PublicLayout>
    );
};
