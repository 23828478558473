import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { AdditionalInfoPage } from "./AdditionalInfoPage";
import { NAME } from "./constants";

export const additionalInfo = typeCheckAppModule({
    NAME,
    Container: AdditionalInfoPage,
    route: Routes.additionalInfo,
});
