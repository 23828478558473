import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { Button, Heading, Input, Loading, PopupWindow, PublicLayout } from "src/components";
import { Routes } from "src/routes";
import { useResendEmailConfirmationMutation } from "src/serverApi";
import { useTranslation } from "src/translations";
import { CONFIRMATION_SEARCH_PARAM } from "./constants";
import { useEmailConfirmation } from "./useEmailConfirmation";

const schema = z.object({
    email: z.string().email(),
});

type FormValues = z.infer<typeof schema>;

export const VerifyEmailPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const confirmationToken = searchParams.get(CONFIRMATION_SEARCH_PARAM);

    const { isLoading, isError } = useEmailConfirmation(confirmationToken ?? undefined);
    const [resendConfirmation, { isLoading: isResending }] = useResendEmailConfirmationMutation();

    const {
        register,
        handleSubmit,
        getFieldState,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: zodResolver(schema),
        defaultValues: { email: "" },
    });

    const onSubmit = async ({ email }: FormValues) => {
        await resendConfirmation({ email });
        await PopupWindow.fire({
            icon: "success",
            title: t("verifyEmail.resendPopUp.title"),
            text: t("verifyEmail.resendPopUp.message"),
            confirmButtonText: t("verifyEmail.resendPopUp.button"),
            timer: 5000,
        });
        navigate(Routes.signIn.path);
    };

    if (!confirmationToken) {
        return <Navigate to={Routes.signIn.path} />;
    }

    if (isLoading) {
        return (
            <PublicLayout>
                <Loading isFullPage />
            </PublicLayout>
        );
    }

    if (isError) {
        return (
            <PublicLayout>
                <Heading level={2}>{t("verifyEmail.failed.title")}</Heading>

                <div className="w-full bg-brand-error/20 p-2.5 text-center text-error">{t("verifyEmail.failed.invalidLink")}</div>

                <form id="Resend confirmation" onSubmit={handleSubmit(onSubmit)} className="w-full space-y-[40px]">
                    <Input
                        label={t("verifyEmail.failed.emailLabel")}
                        max="70"
                        {...register("email")}
                        error={errors.email?.message}
                        fullWidth
                    />

                    <Button type="submit" fullWidth disabled={getFieldState("email").invalid} isLoading={isResending}>
                        {t("verifyEmail.failed.resend")}
                    </Button>
                </form>

                <Button fullWidth onClick={() => navigate(Routes.signIn.path)} kind="outline">
                    {t("verifyEmail.failed.backToLogin")}
                </Button>
            </PublicLayout>
        );
    }

    return null;
};
