import { type FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { CmsError, Loading } from "src/components";
import { Routes } from "src/routes";
import { setAuthToken, useClearReferralCodeMutation, usePublicAppleJwtQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { logGtmEvent } from "src/utils";
import { clearProviderSignUpState, getProviderSignUpState, useUpdateProfileUser } from "../utils";

// TODO: https://morosystems.atlassian.net/browse/IBAY-29 exact copy-paste of GoogleCallbackPage.tsx, consider refactoring
export const AppleCallbackPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const authParams = Object.fromEntries(searchParams.entries()) || {};

    const { data: appleJwt, isLoading, isError, error } = usePublicAppleJwtQuery(authParams);
    const [clearReferralCode] = useClearReferralCodeMutation();
    const updateUserProfile = useUpdateProfileUser();

    const { referralCode, isSignUp, termsAndPrivacyAccepted } = getProviderSignUpState();

    useEffect(() => {
        if (!isLoading && appleJwt) {
            const { jwt } = appleJwt;
            if (jwt) {
                setAuthToken(jwt);
                updateUserProfile({ referredBy: referralCode, termsAndPrivacyAccepted }).then(() => {
                    logGtmEvent({ event: `user_apple_${isSignUp ? "registered" : "logged_in"}` });
                    clearProviderSignUpState();
                    clearReferralCode();
                    navigate(Routes.projects.path);
                });
            }
        }
    }, [isLoading, appleJwt, updateUserProfile, navigate, clearReferralCode, referralCode, isSignUp, termsAndPrivacyAccepted]);

    return isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : <Loading isFullPage />;
};
