import type { ComponentType, FC } from "react";
import { Navigate } from "react-router-dom";

import { MessagesProvider } from "src/messages";
import { Routes } from "src/routes";
import { useUserQuery } from "src/serverApi";
import { Loading } from "../Loading";

export type SecuredContentProps = Readonly<{
    Component: ComponentType;
}>;

export const SecuredContent: FC<SecuredContentProps> = ({ Component }) => {
    const { data: user, isLoading } = useUserQuery();

    if (isLoading) return <Loading isFullPage />;
    if (!user) return <Navigate to={`${Routes.signIn.path}?next=${window.location.pathname}`} />;

    const isInAdditionalInfoPage = window.location.pathname === Routes.additionalInfo.path;
    const { termsAndPrivacyAccepted } = user.userProfile;
    if (!termsAndPrivacyAccepted && !isInAdditionalInfoPage) return <Navigate to={Routes.additionalInfo.path} />;

    return (
        <MessagesProvider>
            <Component />
        </MessagesProvider>
    );
};
