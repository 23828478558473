import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button, PopupWindow } from "src/components";
import { Routes } from "src/routes";
import { removeAuthToken, rootApi, useDeleteAccountMutation } from "src/serverApi";
import { useTranslation } from "src/translations";
import { useAppDispatch } from "src/utils";
import { logger } from "../logger";

export const DeleteAccountButton: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [deleteAccount] = useDeleteAccountMutation();

    const handleDeleteAccount = async () => {
        await PopupWindow.fire({
            icon: "question",
            title: t("settings.deleteAccount.title"),
            text: t("settings.deleteAccount.text"),
            confirmButtonText: t("settings.deleteAccount.confirm"),
            cancelButtonText: t("settings.deleteAccount.deny"),
            showCancelButton: true,
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await deleteAccount()
                    .unwrap()
                    .catch(() => {
                        logger.error("Failed to delete account");
                    });
                removeAuthToken();
                dispatch(rootApi.util.resetApiState());
                navigate(Routes.signIn.path);
            }
        });
    };

    return (
        <Button kind="danger" onClick={handleDeleteAccount}>
            {t("settings.deleteAccount.button")}
        </Button>
    );
};
