import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { z } from "zod";

import { Button, ControlledFormCheckbox, DownloadLink, PublicLayout } from "src/components";
import { useAppConfigQuery } from "src/config";
import { Routes } from "src/routes";
import { isCmsErrorResponseWithMessage, useUserQuery } from "src/serverApi";
import { Trans, useTranslation } from "src/translations";
import { useUpdateProfileUser } from "../utils";
import { logger } from "./logger";

export const AdditionalInfoPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: appConfig } = useAppConfigQuery();
    const { data: user } = useUserQuery();
    const updateUserProfile = useUpdateProfileUser();

    const termsAndConditionsLink = appConfig?.termsAndConditions?.url;
    const termsAndConditionsText = appConfig?.termsAndConditions?.caption;
    const privacyPolicyLink = appConfig?.privacyPolicy?.url;
    const privacyPolicyText = appConfig?.privacyPolicy?.caption;

    const AdditionalInfoSchema = z.object({
        terms: z.boolean().refine(Boolean),
        privacy: z.boolean().refine(Boolean),
    });

    type FormFields = z.infer<typeof AdditionalInfoSchema>;

    const {
        control,
        handleSubmit,
        getFieldState,
        formState: { errors },
    } = useForm<FormFields>({
        resolver: zodResolver(AdditionalInfoSchema),
        defaultValues: {
            terms: false,
            privacy: false,
        },
    });

    const onSubmit = () =>
        updateUserProfile({ termsAndPrivacyAccepted: true })
            .then(() => navigate(Routes.projects.path))
            .catch((error) => logger.error(isCmsErrorResponseWithMessage(error) ? error.data.error.message : "Unknown error", error));

    if (user?.userProfile?.termsAndPrivacyAccepted) return <Navigate to={Routes.projects.path} />;

    return (
        <PublicLayout title={t("additionalInfo.title")}>
            <p>{t("additionalInfo.text")}</p>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
                {termsAndConditionsLink && (
                    <Controller
                        name="terms"
                        control={control}
                        render={({ field }) => (
                            <>
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label className="flex items-center gap-2">
                                    <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                    <span>
                                        <Trans
                                            i18nKey="signUp.agree.text"
                                            components={{
                                                downloadLink: <DownloadLink className="text-base" href={termsAndConditionsLink} />,
                                            }}
                                            values={{ linkText: termsAndConditionsText }}
                                        />
                                    </span>
                                </label>
                                {errors.terms && <span className="text-sm text-error">{t("signUp.agree.error")}</span>}
                            </>
                        )}
                    />
                )}
                {privacyPolicyLink && (
                    <Controller
                        name="privacy"
                        control={control}
                        render={({ field }) => (
                            <>
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label className="flex items-center gap-2">
                                    <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                    <span>
                                        <Trans
                                            i18nKey="signUp.agree.text"
                                            components={{
                                                downloadLink: <DownloadLink className="text-base" href={privacyPolicyLink} />,
                                            }}
                                            values={{ linkText: privacyPolicyText }}
                                        />
                                    </span>
                                </label>
                                {errors.privacy && <span className="text-sm text-error">{t("signUp.agree.error")}</span>}
                            </>
                        )}
                    />
                )}
                <Button fullWidth type="submit" disabled={getFieldState("terms").invalid || getFieldState("privacy").invalid}>
                    {t("additionalInfo.submit")}
                </Button>
            </form>
        </PublicLayout>
    );
};
