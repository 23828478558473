import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { AppleCallbackPage } from "./AppleCallbackPage";
import { NAME } from "./constants";

export { AppleButton } from "./AppleButton";

export const appleCallback = typeCheckAppModule({
    NAME,
    Container: AppleCallbackPage,
    route: Routes.appleCallback,
    secure: false,
});
