import { HttpMethod } from "../constants";
import { rootApi } from "../rootApi";
import { getCmsUrl } from "../utils";

export const referralApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        getReferralCode: build.query<string, void>({
            query: () => ({
                url: getCmsUrl("/api/user-profiles/referral"),
                method: HttpMethod.GET,
                credentials: "include",
                responseHandler: "text",
            }),
            // always refetch fresh referral code from CMS
            keepUnusedDataFor: 0,
        }),
        clearReferralCode: build.mutation<void, void>({
            query: () => ({
                url: getCmsUrl("/api/user-profiles/referral"),
                method: HttpMethod.DELETE,
                credentials: "include",
            }),
        }),
        registerEhubUser: build.query<void, { userId: number; visitId: string }>({
            query: ({ userId, visitId }) => ({
                url: "https://ehub.cz/system/scripts/sale.php",
                method: HttpMethod.GET,
                mode: "no-cors", // we don't care about the response
                params: {
                    orderId: userId,
                    visitId,
                },
            }),
        }),
    }),
});

export const { useGetReferralCodeQuery, useClearReferralCodeMutation, useLazyRegisterEhubUserQuery } = referralApi;
