import type { FC } from "react";

import { Button, PopupWindow } from "src/components";
import { useEmptyOutWalletMutation, useWalletBalanceQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { Currency } from "src/types";
import { logger } from "../logger";

export const EmptyOutWalletButton: FC = () => {
    const { t } = useTranslation();

    const { data: czkWallet, isLoading: isLoadingCzkWallet } = useWalletBalanceQuery({ currency: Currency.CZK });
    const { data: eurWallet, isLoading: isLoadingEurWallet } = useWalletBalanceQuery({ currency: Currency.EUR });
    const [emptyOutWallet] = useEmptyOutWalletMutation();

    const isLoading = isLoadingCzkWallet || isLoadingEurWallet;
    const isWalletEmpty = !isLoading && czkWallet?.balance === 0 && eurWallet?.balance === 0;

    // TODO: disallow repeating the action

    const handleEmptyOutWallet = async () => {
        await PopupWindow.fire({
            icon: "question",
            title: t("settings.emptyOutWallet.title"),
            text: t("settings.emptyOutWallet.text"),
            confirmButtonText: t("settings.emptyOutWallet.confirm"),
            cancelButtonText: t("settings.emptyOutWallet.deny"),
            showCancelButton: true,
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await emptyOutWallet()
                    .unwrap()
                    .catch(() => {
                        logger.error("Failed to empty out wallet");
                    });
            }
        });
    };

    return (
        <Button kind="primary" onClick={handleEmptyOutWallet} isLoading={isLoading} disabled={isWalletEmpty}>
            {t("settings.emptyOutWallet.button")}
        </Button>
    );
};
