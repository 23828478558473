import { useCallback, useEffect, useRef, useState } from "react";

import { getCmsUrl } from "src/serverApi";

type EmailConfirmationResult = {
    isLoading: boolean;
    isError: boolean;
};

export const useEmailConfirmation = (confirmation?: string): EmailConfirmationResult => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const hasRun = useRef(false);

    const sendConfirmation = useCallback(async (confirmationCode: string) => {
        setIsLoading(true);
        setIsError(false);

        try {
            const response = await fetch(getCmsUrl(`/api/auth/email-confirmation?confirmation=${confirmationCode}`), {
                method: "GET",
                redirect: "follow",
            });

            if (response.redirected && response.url) {
                window.location.href = response.url;
                return;
            }

            if (!response.ok) {
                throw new Error("Confirmation failed");
            }
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (confirmation && !hasRun.current) {
            hasRun.current = true;
            sendConfirmation(confirmation);
        }
    }, [confirmation, sendConfirmation]);

    return { isLoading, isError };
};
