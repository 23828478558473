import { type FC, useState } from "react";

import { Button, IconWithPopover } from "src/components";
import { CheckIcon, InfoIcon } from "src/icons";
import { Routes } from "src/routes";
import { getUser } from "src/serverApi";
import { useTranslation } from "src/translations";
import { useAppSelector } from "src/utils";

export const ReferralCode: FC = () => {
    const { t } = useTranslation();
    const user = useAppSelector(getUser);

    const [isCopied, setIsCopied] = useState(false);

    const copyLinkToClipboard = () => {
        const link = `${window.location.origin}${Routes.signUp.path}?referral=${user.userProfile.referralCode}`;
        navigator.clipboard.writeText(link);
        setIsCopied(true);
    };

    return (
        <div className="flex flex-col flex-wrap gap-2">
            <p className="flex items-center gap-1 text-sm">
                <span>{t("settings.referralCode.label")}</span>
                <IconWithPopover icon={<InfoIcon className="size-4 fill-primary" />}>
                    {t("settings.referralCode.description")}
                </IconWithPopover>
            </p>

            <p className="mb-2 text-xl font-bold">{user.userProfile.referralCode}</p>
            <Button className="relative w-full items-center justify-center" kind="outline" onClick={copyLinkToClipboard}>
                <span>{t("settings.referralCode.copy")}</span>
                {isCopied && <CheckIcon className="absolute right-2 size-7 fill-brand" />}
            </Button>
        </div>
    );
};
