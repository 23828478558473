import type { FC, InputHTMLAttributes } from "react";
import { forwardRef, useReducer } from "react";

import { EyeCloseIcon, EyeIcon } from "src/icons";
import { cn } from "src/utils";
import { Input } from "./Input";

type SecretInputProps = Readonly<{
    name: string;
    label?: string;
    error?: string;
}> &
    InputHTMLAttributes<HTMLInputElement>;

export const SecretInput: FC<SecretInputProps> = forwardRef<HTMLInputElement, SecretInputProps>(({ label, ...props }, ref) => {
    const [showPassword, toggleShowPassword] = useReducer((prev) => !prev, false);

    return (
        <div className="relative flex w-full">
            <Input label={label} type={showPassword ? "text" : "password"} ref={ref} {...props} />
            <button
                type="button"
                className={cn("ml-2 flex size-10 items-center justify-center", label && "mt-7")}
                onClick={toggleShowPassword}
            >
                {showPassword ? <EyeCloseIcon /> : <EyeIcon />}
            </button>
        </div>
    );
});

SecretInput.displayName = "SecretInput";
