import type { FC } from "react";

import { ButtonWithIcon } from "src/components";
import { AppleIcon } from "src/icons";
import { getCmsUrl } from "src/serverApi";
import { useTranslation } from "src/translations";
import { logGtmEvent } from "src/utils";
import { setProviderSignUpState } from "../utils";

type AppleButtonProps = Readonly<{
    signUp?: boolean;
    referralCode?: string;
    triggerValidation?: () => Promise<boolean>;
}>;

export const AppleButton: FC<AppleButtonProps> = ({ referralCode, triggerValidation, signUp = false }) => {
    const { t } = useTranslation();

    const handleClick = async () => {
        const termsAndPrivacyAccepted = await triggerValidation?.();
        if (triggerValidation && !termsAndPrivacyAccepted) return;

        if (signUp) {
            setProviderSignUpState({ isSignUp: true, referralCode, termsAndPrivacyAccepted });
        }

        logGtmEvent({ event: `user_apple_${signUp ? "registration" : "login"}_triggered` });
        window.location.href = getCmsUrl("/api/connect/apple");
    };

    return (
        <ButtonWithIcon icon={<AppleIcon />} className="w-full border-black bg-white text-black hover:bg-white" onClick={handleClick}>
            {t("apple.button.title")}
        </ButtonWithIcon>
    );
};
